/**
 * --------------------------------------------------------------------------
 * Toast Notification Library
 * --------------------------------------------------------------------------
 * This module provides a simple API for displaying toast notifications.
 *
 * Usage examples:
 * Toast.show('Success', 'Your changes have been saved!', 'success');
 * Toast.show('Error', 'Something went wrong.', 'danger');
 */

let Toast = (() => {
    let toastContainer = null;

    // Ensures the toast container exists
    const ensureContainer = () => {
        if (!toastContainer) {
            $('body').append('<div class="toast-container position-fixed z-3"></div>');
            toastContainer = $('.toast-container');
        }
    };

    // Displays a toast notification
    const show = (title, message, type = 'info') => {
        ensureContainer();
        toastContainer.find('.toast').remove();

        const bgClass = {
            success: 'text-bg-success',
            danger: 'text-bg-danger',
            warning: 'text-bg-warning',
            info: 'text-bg-info'
        }[type] || 'text-bg-primary';

        const toastElement = $(`
            <div class="toast align-items-center ${bgClass} border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body p-3">${message}</div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-coreui-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        `);

        toastContainer.append(toastElement);
        const toastInstance = new coreui.Toast(toastElement[0]);
        toastInstance.show();
    };

    return {
        show
    };
})();

// Expose Toast globally
window.Toast = Toast;
