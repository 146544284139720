/**
 * --------------------------------------------------------------------------
 * Global Variables
 * --------------------------------------------------------------------------
 * This script initializes global variables used throughout the application.
 */

// Global variable to store CSRF token
window.token = $('meta[name="csrf-token"]').attr('content');

