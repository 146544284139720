/**
 * --------------------------------------------------------------------------
 * DateTimeUpdater
 * --------------------------------------------------------------------------
 * This script updates the date and time display dynamically.
 *
 * Usage example:
 * DateTimeUpdater.configure({ dateSelector: "#custom-date", timeSelector: "#custom-time" });
 * DateTimeUpdater.start();
 *
 */
let DateTimeUpdater = (() => {
    let selectors = { date: "#datetime-container .date", time: "#datetime-container .time" };

    const updateDateTime = () => {
        const now = new Date();
        const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };

        const formattedDate = now.toLocaleDateString('en-US', optionsDate);
        const formattedTime = now.toLocaleTimeString('en-US', optionsTime);

        document.querySelector(selectors.date).textContent = formattedDate;
        document.querySelector(selectors.time).textContent = formattedTime;
    };

    // Configures custom selectors for date and time elements
    const configure = (options) => {
        if (options.dateSelector) selectors.date = options.dateSelector;
        if (options.timeSelector) selectors.time = options.timeSelector;
    };

    // Start updating the time every second
    const start = () => {
        updateDateTime();
        setInterval(updateDateTime, 1000);
    };

    return { start, configure };
})();

// Expose DateTimeUpdater globally
window.DateTimeUpdater = DateTimeUpdater;
