/**
 * --------------------------------------------------------------------------
 * Task Scheduler
 * --------------------------------------------------------------------------
 * This script handles scheduling tasks at a specific time daily or at fixed intervals.
 *
 * Usage examples:
 * Schedule a task to run once daily at 2:30 AM
 * TaskScheduler.addTask(fetchLogs, { hours: 2, minutes: 30 });
 *
 * Schedule a task to run every 6 hours
 * TaskScheduler.addTask(fetchLogs, { intervalHours: 6 });
 *
 * Schedule a task to run every 1 hour and 30 minutes
 * TaskScheduler.addTask(fetchLogs, { interval: { hours: 1, minutes: 30 } });
 */
let TaskScheduler = (() => {
    let taskQueue = [];

    // Schedules the next execution of a task
    const scheduleNextRun = (task, options) => {
        const { hours = 0, minutes = 0, seconds = 0, interval = null, intervalHours = null } = options;
        const now = new Date();
        let nextRun = new Date();

        if (interval !== null || intervalHours !== null) {
            let intervalMs = (intervalHours || 0) * 60 * 60 * 1000 +
                (interval?.hours || 0) * 60 * 60 * 1000 +
                (interval?.minutes || 0) * 60 * 1000;
            nextRun.setTime(now.getTime() + intervalMs);
        } else {
            nextRun.setHours(hours, minutes, seconds, 0);
            if (nextRun <= now) {
                nextRun.setDate(nextRun.getDate() + 1);
            }
        }

        const timeUntilNextRun = nextRun - now;

        console.log(`Task scheduled to run in ${timeUntilNextRun / 1000} seconds`);

        setTimeout(() => {
            if (typeof task === "function") {
                console.log("Executing scheduled task at:", new Date().toLocaleTimeString());
                task();
            } else {
                console.error("Scheduled task is not a function:", task);
            }
            scheduleNextRun(task, options);
        }, timeUntilNextRun);
    };

    // Adds a task to the scheduler
    const addTask = (task, options = {}) => {
        if (typeof task !== "function") {
            console.error("Task must be a function", task);
            return;
        }
        console.log("Adding task to scheduler:", options);
        const taskEntry = { task, options };
        taskQueue.push(taskEntry);
        scheduleNextRun(task, options);
    };

    // Retrieves the list of scheduled tasks
    const getScheduledTasks = () => {
        return taskQueue.map((entry, index) => ({ id: index + 1, options: entry.options }));
    };

    return {
        addTask,
        getScheduledTasks
    };
})();

// Expose TaskScheduler globally
window.TaskScheduler = TaskScheduler;

// Retrieve scheduled tasks
console.info("Scheduled Tasks:", TaskScheduler.getScheduledTasks());
