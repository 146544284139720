/**
 * --------------------------------------------------------------------------
 * Keyboard Shortcuts
 * --------------------------------------------------------------------------
 * This script adds keyboard shortcuts for various actions within the application.
 */

// Keyboard shortcut for refresh (Ctrl+Alt+R)
$(document).on('keydown', function(e) {
    // Check if Control key, Alt key, and 'r' key are pressed
    if (e.ctrlKey && e.altKey && e.key === 'r') {
        $('#reload').trigger('click');
    }
});

// Keyboard shortcut for focusing the DataTables search input (Ctrl+F)
$(document).on('keydown', function(e) {
    // Check if Control key, and 'f' key are pressed
    if (e.ctrlKey && e.key === 'f') {
        // Prevent the default browser search
        e.preventDefault();
        // Focus the DataTables search input
        $('#customSearchBox').focus();
    }
});
