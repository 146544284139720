/**
 * --------------------------------------------------------------------------
 * jQuery Extensions
 * --------------------------------------------------------------------------
 * This file contains various jQuery extensions that enhance UI functionalities.
 *
 * The extensions provide functions for:
 * - Highlighting table columns on mouse enter and removing highlight on mouse leave.
 * - Reloading table data with an animation and a keyboard shortcut for refresh.
 * - Redrawing tables on window resize.
 * - Populating dropdown lists asynchronously, including cascading dropdown lists.
 * - Converting strings to proper case.
 * - Changing input types based on window size.
 * - Formatting date input fields as per requirements.
 * - Converting dates to localized string formats.
 * - Disabling the context menu (right-click menu).
 * - Preventing text selection and highlighting.
 * - Storing data in local storage.
 * - Displaying various types of messages (info, success, warning, error).
 * - Formatting date and time with optional configurations.
 */

$.fn.extend({
    // Function to highlight table column on mouse enter and remove highlighting on mouse leave
    highlightTableColumn(table) {
        // Store the DataTable reference once for better performance
        const dt = table.DataTable();

        $(this).on({
            mouseenter: function() {
                const columnIndex = dt.column(this).index();
                dt.column(columnIndex).nodes().to$().addClass('highlight');
            },
            mouseleave: function() {
                const columnIndex = dt.column(this).index();
                dt.column(columnIndex).nodes().to$().removeClass('highlight');
            }
        });
    },

    // Function to reload table data with animation and keyboard shortcut
    reloadTable(table) {
        // Store the DataTable reference once for consistency
        const dt = table.DataTable();

        $(this).on('click', function(e) {
            const icon = $(this).find('.icon');

            // Add animation class
            icon.addClass('rotate');

            // Simulate some loading time
            setTimeout(function() {
                // Remove animation class
                icon.removeClass('rotate');
                // Reload table data
                dt.ajax.reload(null, true);
            }, 1000);

            e.preventDefault();
        });
    },

    // Function to redraw table on window resize
    redrawTable(table) {
        // Store the DataTable reference once for clarity
        const dt = table.DataTable();

        $(this).on('resize', function() {
            dt.columns.adjust().draw();
        });
    },

    // Function to populate dropdown list asynchronously
    async dropdownList(url, param, valueType, el) {
        try {
            // Fetch dropdown data
            const data = await $.getJSON(url + param);
            const parsedData = JSON.parse(data).data;

            // Populate dropdown with fetched data
            $.each(parsedData, function(key, value) {
                el.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name
                    })
                );
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },

    // Function to populate cascading dropdown lists asynchronously
    async cascadingDropdownList(parentUrl, childUrl, defaultOptionText, paramType, valueType, parentDropdown, childDropdown) {
        try {
            // Fetch data for parent dropdown
            const parentData = await $.getJSON(parentUrl);
            const parsedParentData = JSON.parse(parentData).data;

            // Populate parent dropdown with fetched data
            $.each(parsedParentData, function(key, value) {
                parentDropdown.append(
                    $('<option>', {
                        value: valueType === 'text' ? value.name : value.id,
                        text: value.name,
                        'data-id': value.id
                    })
                );
            });

            // Handle parent dropdown change event
            parentDropdown.on('change', async function() {
                const selectedOption = $(this).find('option:selected');
                const selectedText = selectedOption.text();
                const selectedValue = selectedOption.data('id');

                // Clear existing options in child dropdown
                childDropdown.empty();
                // Add a default option with dynamic text
                childDropdown.append(
                    $('<option>', {
                        value: '',
                        text: defaultOptionText,
                        selected: true,
                        disabled: true,
                        hidden: true
                    })
                );

                const param = paramType === 'text' ? selectedText : selectedValue;

                // Fetch data for child dropdown based on the selected parameter
                const childData = await $.getJSON(childUrl + '?param=' + param);
                const parsedChildData = JSON.parse(childData).data;

                // Populate child dropdown with fetched data
                $.each(parsedChildData, function(key, value) {
                    childDropdown.append(
                        $('<option>', {
                            value: valueType === 'text' ? value.name : value.id,
                            text: value.name
                        })
                    );
                });
            });
        } catch (error) {
            console.error('Error fetching dropdown data:', error);
        }
    },

    // Function to convert string to proper case
    toProperCase(string) {
        return string.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    },

    // Function to change input type based on window size
    inputText2Number(input) {
        const mediaQuery = window.matchMedia('(min-width: 992px)');

        const handleWindowChange = (mq) => {
            if (mq.matches) {
                input
                    .attr('type', 'text')
                    .removeAttr('step pattern');
            } else {
                input.attr({
                    type: 'number',
                    step: 'any',
                    pattern: '[0-9]*[.,]?[0-9]+'
                });
            }
        };

        // Call the handler initially
        handleWindowChange(mediaQuery);

        // Attach the handler to the media query
        mediaQuery.addEventListener('change', (e) => handleWindowChange(e.target));

        return this;
    },

    // Function to disable context menu (right click menu)
    disableContextMenu() {
        $('body').on('contextmenu', function(e) {
            e.preventDefault();
        });
    },

    // Function to disable text highlighting
    disableHighLighting() {
        $('body').on('selectstart', function(e) {
            e.preventDefault();
        });
    },

    // Function to store data in a localStorage
    saveToLocalStorage(key, value) {
        if (typeof Storage !== 'undefined') {
            localStorage.setItem(key, value);
        } else {
            console.log('Sorry! No Web Storage support..');
        }
    },

    // Function to display info message
    info(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('info', msg));
        });
    },

    // Function to display success message
    success(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('success', msg));
        });
    },

    // Function to display success message
    warning(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('warning', msg));
        });
    },

    // Function to display error message
    error(msg) {
        return this.each(function() {
            $(this).find('.notif-body').html($.fn.createCallout('danger', msg));
        });
    },

    // Helper function to create Bootstrap callout
    createCallout(type, msg) {
        const calloutClass = `callout callout-${type}`;
        return `<div class="${calloutClass}">${msg}</div>`;
    },

    // Function to format date input as per requirement
    dateFormatter(options) {
        // Default settings
        const settings = $.extend({
            dateInput: 'input[name="date_input"]',
            dateOutput: 'input[name="date"]' || 'input[name="birthdate"]'
        }, options);

        // Attach input event handler to the matched elements
        this.on('input', function() {
            const dateInput = $(settings.dateInput, this);
            const dateOutput = $(settings.dateOutput, this);
            const dateValue = dateInput.val();

            // Use regex to extract day, month, and year from the date input
            const match = dateValue.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);

            if (match) {
                const [ , day, month, year ] = match;
                const formattedDate = new Date(`${year}-${month}-${day}`);

                // Check if the formatted date is valid
                if (!isNaN(formattedDate.getTime())) {
                    dateOutput.val(`${year}-${month}-${day}`);
                } else {
                    dateOutput.val('');
                }
            } else {
                dateOutput.val('');
            }
        });

        return this; // Enable chaining
    },

    formatDateTime(dateString, useLongMonth = false, includeTime = true) {
        const timestamp = Date.parse(dateString);
        if (isNaN(timestamp)) return ''; // Return empty string if invalid date

        const date = new Date(timestamp);

        const options = {
            year: 'numeric',
            month: useLongMonth ? 'long' : '2-digit',
            day: '2-digit',
        };

        if (includeTime) {
            options.hour = '2-digit';
            options.minute = '2-digit';
            options.hour12 = true;
        }

        return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '');
    }
});
