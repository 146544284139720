/**
 * --------------------------------------------------------------------------
 * Account Lockout
 * --------------------------------------------------------------------------
 * This script handles account lockout due to inactivity, using native event listeners.
 */
let InactivityLockout = (() => {
    const USER_ACTIVITY_EVENTS = ['mousemove', 'keydown', 'click', 'touchstart', 'touchmove'];
    let idleTimerId;
    let inactivityTimeout = 300000; // Default 5 minutes
    let onLock = null;
    let isRunning = false;

    // Locks the account
    const lockAccount = () => {
        if (onLock) {
            onLock();
        } else {
            alert('Account locked due to inactivity');
        }
    };

    // Schedules the lock based on inactivity
    const scheduleLock = () => {
        idleTimerId = setTimeout(lockAccount, inactivityTimeout);
    };

    // Resets the current schedule and schedules a new lock
    const resetLockSchedule = () => {
        clearTimeout(idleTimerId);
        scheduleLock();
    };

    // Configures lockout options
    const configure = (options) => {
        if (options.timeoutDuration) {
            inactivityTimeout = options.timeoutDuration;
        }
        if (options.lockCallback) {
            onLock = options.lockCallback;
        }
    };

    // Attaches native event listeners
    const attachEventListeners = () => {
        USER_ACTIVITY_EVENTS.forEach((evt) => {
            document.addEventListener(evt, resetLockSchedule);
        });
    };

    // Detaches native event listeners
    const detachEventListeners = () => {
        USER_ACTIVITY_EVENTS.forEach((evt) => {
            document.removeEventListener(evt, resetLockSchedule);
        });
    };

    // Starts the lockout functionality
    const start = () => {
        if (!isRunning) {
            isRunning = true;
            scheduleLock();
            attachEventListeners();
        }
    };

    // Resets the lockout timer
    const reset = () => {
        clearTimeout(idleTimerId);
        scheduleLock();
    };

    // Stops the lockout functionality completely
    const stop = () => {
        if (isRunning) {
            isRunning = false;
            clearTimeout(idleTimerId);
            detachEventListeners();
        }
    };

    return {
        configure,
        start,
        reset,
        stop
    };
})();

// Expose InactivityLockout globally
window.InactivityLockout = InactivityLockout;
