/**
 * --------------------------------------------------------------------------
 * Time Ago Library
 * --------------------------------------------------------------------------
 * This module provides a simple API for converting dates to a "time ago" format.
 *
 * Usage examples:
 * console.log(TimeAgo.format(new Date())); // "just now"
 * console.log(TimeAgo.format(new Date() - 60000)); // "1 minute ago"
 */

let TimeAgo = (() => {
    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 }
    ];

    // Converts a date to a "time ago" format
    const format = (date) => {
        const now = new Date();
        const secondsElapsed = Math.floor((now - new Date(date)) / 1000);

        if (secondsElapsed < 5) return 'Just Now';
        if (secondsElapsed < 60) return `${secondsElapsed} seconds ago`;

        for (let interval of intervals) {
            const count = Math.floor(secondsElapsed / interval.seconds);
            if (count >= 1) {
                return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
            }
        }
    };

    return {
        format
    };
})();

// Expose TimeAgo globally
window.TimeAgo = TimeAgo;
