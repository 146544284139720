/**
 * --------------------------------------------------------------------------
 * Weather Fetcher
 * --------------------------------------------------------------------------
 * This script fetches and displays weather data dynamically.
 *
 * Usage example:
 * WeatherFetcher.configure({ lat: 8.720861, lon: 125.754318, container: "#weather-container", imagePath: `${location.origin}/build/assets/img/weather/symbols/lightmode/png/100/` });
 * WeatherFetcher.fetchWeather();
 *
 */
let WeatherFetcher = (() => {
    let config = {
        lat: 8.720861,
        lon: 125.754318,
        container: "#weather-container",
        imagePath:  `${location.origin}/build/assets/img/weather/symbols/lightmode/png/100/`
    };

    // Configures custom settings
    const configure = (options) => {
        if (options.lat) config.lat = options.lat;
        if (options.lon) config.lon = options.lon;
        if (options.container) config.container = options.container;
        if (options.imagePath) config.imagePath = options.imagePath;
    };

    // Fetches weather data and updates the DOM
    const fetchWeather = async () => {
        try {
            let response = await fetch(`https://api.met.no/weatherapi/locationforecast/2.0/complete?lat=${config.lat}&lon=${config.lon}`);
            let data = await response.json();
            let forecast = data.properties.timeseries[0].data.instant.details;
            let weatherSymbol = data.properties.timeseries[0].data.next_1_hours?.summary?.symbol_code || "default";
            let weatherImage = `${config.imagePath}${weatherSymbol}.png`;

            let temperatureC = forecast.air_temperature;
            let temperatureF = (temperatureC * 9/5) + 32;

            // Format weather symbol for display
            let formattedWeatherSymbol = formatWeatherSymbol(weatherSymbol);

            document.querySelector(config.container).innerHTML = `
                <div class="d-flex align-items-center justify-content-center">
                    <img src="${weatherImage}" alt="Weather Icon" class="me-3" width="80">
                    <div>
                        <h5>${formattedWeatherSymbol}</h5>
                        <p>Temperature: <br/>
                            ${temperatureC.toFixed(1)}&deg;C / ${temperatureF.toFixed(1)}&deg;F
                        </p>
                    </div>
                </div>
            `;
        } catch (error) {
            console.error("Error fetching weather:", error);
        }
    };

    // Formats the weather symbol text
    const formatWeatherSymbol = (symbol) => {
        const weatherTerms = [
            "clear", "sky", "fair", "partly", "cloudy", "rain", "showers", "heavy", "light",
            "lights", "sleet", "snow", "thunder", "and", "day", "night", "polartwilight"
        ];

        return symbol.replace(/_/g, " ")
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(new RegExp(weatherTerms.join("|"), "gi"), (match) => " " + match)
            .trim()
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    return { configure, fetchWeather };
})();

// Expose WeatherFetcher globally
window.WeatherFetcher = WeatherFetcher;
